import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'phoneNumber'})
export class PhoneNumber implements PipeTransform {
  transform(text: string) {
    const areaCode = text.substring(0, 3)
    const number1 = text.substring(3, 6)
    const number2 = text.substring(6)
    return `(${areaCode}) ${number1}-${number2}`;
  }
}
