import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  form: FormGroup;
  isBusy = false;
  hide = true;
  @Input() appName: string;
  @Input() referrer: string;
  @Output() submit = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private matSnackBar: MatSnackBar, private userService: UserService) {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,13}$/)]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      promotionalCode: ['']
    });
  }

  ngOnInit() {
    this.promotionalCode.setValue(this.referrer);
  }

  signup(event) {
    event.preventDefault();
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].markAsTouched();
    });
    if (this.form.valid) {
      this.isBusy = true;
      this.userService.invite({
        ...this.form.value,
        appName: this.appName
      }).then((data: any) => {
        const snackbar = this.matSnackBar.open('Success', 'Dismiss', {
            duration: 2000,
            panelClass: ['success-snackbar']
          });

        this.isBusy = false;
        this.submit.emit(data);

        snackbar.afterOpened().subscribe(() => {
          window.scrollTo(0, 0);
        });
      }).catch(err => {
        this.isBusy = false;
        const snackbar = this.matSnackBar.open(err.error.message || 'Account could not be created', 'Dismiss', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

        snackbar.afterOpened().subscribe(() => {
          window.scrollTo(0, 0);
        });
      });
    }
  }

  toggleHide(e) {
    e.preventDefault();
    this.hide = !this.hide;
  }

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

  get username() {
    return this.form.get('username');
  }

  get password() {
    return this.form.get('password');
  }

  get email() {
    return this.form.get('email');
  }

  get postalCode() {
    return this.form.get('postalCode');
  }

  get mobile() {
    return this.form.get('mobile');
  }

  get promotionalCode() {
    return this.form.get('promotionalCode');
  }
}
