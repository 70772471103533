import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class InviteResolver implements Resolve<any> {
  constructor(
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return Observable.create((observer) => {
      observer.next(route.queryParams);
      observer.complete();
    })
  }
}
