import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent {
  form: FormGroup;
  isBusy = false;
  @Input() appName: string;
  @Input() user: any;
  @Input() organization: any;
  @Output() verify = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private matSnackBar: MatSnackBar, private userService: UserService) {
    this.form = this.fb.group({
      verificationCode: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]]
    });
  }

  validateVerificationCode(event) {
    event.preventDefault();
    this.verificationCode.markAsTouched();

    if (this.form.valid) {
      this.isBusy = true;
      this.userService.validateVerificationCode({
        smsCode: this.verificationCode.value
      }).then(() => {
        this.isBusy = false;
        const snackBarRef = this.matSnackBar.open('Success', 'Dismiss', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });

        snackBarRef.afterDismissed().toPromise()
          .then(() => {
            this.verify.emit(true);
          });
      }).catch((err) => {
        this.isBusy = false;
        const snackBarRef = this.matSnackBar.open(err.statusText || 'Something went wrong', 'Dismiss', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

        snackBarRef.afterDismissed().toPromise()
          .then(() => {
            this.verify.emit(false);
          });
      });
    }
  }



  get verificationCode() {
    return this.form.get('verificationCode');
  }
}
