import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  appName: string;
  email: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data
      .subscribe((data) => {
        switch (data.data.a) {
          case 'pacific':
          case 'Pacific':
            this.appName = 'Pacific/Food Depot';
            this.email = 'pacificmarketca@gmail.com';
            break;
          case 'luckymoose':
          case 'LuckyMoose':
            this.appName = 'Lucky Moose';
            this.email = 'luckymooseapp@gmail.com';
            break;
          default:
            this.appName = 'GOcery';
            this.email = 'support@gocery.ca';
            break;
        }
      });
  }

}
