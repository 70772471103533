import { Component, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit, OnDestroy {
  public appName: string;
  // private s: any;

  constructor(private renderer2: Renderer2, @Inject(DOCUMENT) private document) {}

  ngOnInit() {
    // this.s = this.renderer2.createElement('script');
    // this.s.type = 'text/javascript';
    // this.s.text = `
    // var axel = Math.random() + "";
    // var a = axel * 10000000000000;
    // document.write('<img src="https://ad.doubleclick.net/ddm/activity/src=10089725;type=invmedia;cat=9940-0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=' + a + '?" width="1" height="1" alt=""/>');`;
    // this.renderer2.appendChild(this.document.body, this.s);
  }

  ngOnDestroy() {
    // this.renderer2.removeChild(this.document.body, this.s);
  }
}
