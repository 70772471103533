import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MailService } from '../../../services/mail.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  @ViewChild('subscribeForm') subscribeForm: NgForm;
  error: boolean = false;
  emailAdded: boolean = false;

  constructor(private mailService: MailService) { }

  ngOnInit() {
  }

  addSubscription(email) {
    if (this.subscribeForm.invalid) {
      this.subscribeForm.form.get('email').markAsDirty();
      return;
    }

    this.error = false;
    this.mailService.addMember(email)
      .subscribe(() => this.emailAdded = true, // Show success
        error => {
          this.error = true;
        });
  }

}
