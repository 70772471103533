import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MatDialogModule } from '@angular/material/dialog';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FeaturesListComponent } from './components/sections/features-list/features-list.component';
import { VideoComponent } from './components/sections/video/video.component';
import { PartnersComponent } from './components/sections/partners/partners.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { InstructionsComponent } from './components/sections/instructions/instructions.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatIconModule, MatSnackBarModule, MatProgressSpinnerModule } from '@angular/material';
import { HomeComponent } from './components/pages/home/home.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { SubscribeComponent } from './components/pages/subscribe/subscribe.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SubscribeLinkComponent } from './components/sections/subscribe-link/subscribe-link.component';
import { SubscribeModalComponent } from './components/sections/subscribe-modal/subscribe-modal.component';
import { SubscribeModalSuccessComponent } from './components/sections/subscribe-modal-success/subscribe-modal-success.component';
import { SubscribeModalErrorComponent } from './components/sections/subscribe-modal-error/subscribe-modal-error.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { UserService } from './services/user.service';
import { MailService } from './services/mail.service';
import { WelcomeComponent } from './components/pages/sign-up/welcome.component';
import { InviteComponent } from './components/pages/invite/invite.component';
import { VerifyComponent } from './components/pages/sign-up/verify.component';
import { PhoneNumber } from './pipes/phone-number.pipe';
import { DownloadsComponent } from './components/pages/downloads/downloads.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FeaturesListComponent,
    VideoComponent,
    PartnersComponent,
    FooterComponent,
    InstructionsComponent,
    HomeComponent,
    TermsComponent,
    PrivacyComponent,
    SubscribeComponent,
    SubscribeLinkComponent,
    SubscribeModalComponent,
    SubscribeModalSuccessComponent,
    SubscribeModalErrorComponent,
    InviteComponent,
    SignUpComponent,
    WelcomeComponent,
    VerifyComponent,
    PhoneNumber,
    DownloadsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSnackBarModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [
    SubscribeModalComponent,
    SubscribeModalSuccessComponent,
    SubscribeModalErrorComponent
  ],
  providers: [
    UserService,
    MailService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
