import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MailService } from 'src/app/services/mail.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SubscribeModalSuccessComponent } from '../subscribe-modal-success/subscribe-modal-success.component';
import { SubscribeModalErrorComponent } from '../subscribe-modal-error/subscribe-modal-error.component';

@Component({
  selector: 'app-subscribe-modal',
  templateUrl: './subscribe-modal.component.html',
  styleUrls: ['./subscribe-modal.component.scss']
})
export class SubscribeModalComponent implements OnInit {

  @ViewChild('subscribeForm') subscribeForm: NgForm;
  error = false;
  emailAdded = false;

  constructor(
    public dialogRef: MatDialogRef<SubscribeModalComponent>,
    private mailService: MailService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  addSubscription(email) {
    if (this.subscribeForm.invalid) {
      this.subscribeForm.form.get('email').markAsDirty();
      return;
    }

    this.error = false;
    this.mailService.addMember(email)
      .subscribe(
        // Show success dialog
        () => {
          this.dialogRef.close();
          this.dialog.open(SubscribeModalSuccessComponent,
            { panelClass: 'custom-dialog-container' });
        },
        error => {
          this.error = true;
          this.dialogRef.close();
          this.dialog.open(SubscribeModalErrorComponent,
            { panelClass: 'custom-dialog-container' });
        });
  }

}
