import { Injectable } from '@angular/core';
import { REST_ENDPOINT } from '../utils/constants';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const baseURL = `${REST_ENDPOINT}/users/subscribe`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class MailService {

  constructor(private http: HttpClient) { }

  addMember(email): Observable<string> {
    return this.http.post<string>(`${baseURL}`, {email}, httpOptions).pipe(
      catchError(e => throwError(e))
    );
  }
}
