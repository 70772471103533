import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  public submitted = false;
  public verified = false;
  public appName: string;
  public referrer: string;
  public user: any;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
      if (data && data.data) {
        this.appName = data.data.a && data.data.a.toLowerCase();
        this.referrer = data.data.c && data.data.c.toLowerCase();
      }
    });
  }

  onSubmit(data) {
    this.user = data;
    this.submitted = true;
  }

  onVerify(verified) {
    this.verified = verified;
  }
}
