import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('hideShow', [
      state('hidden', style({
        top: '-70px'
      })),
      state('visible', style({
        top: '0'
      })),
      transition('hidden => visible', [
        animate('0.3s')
      ]),
      transition('visible => hidden', [
        animate('0.4s')
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit {
  public isVisible: boolean;
  public appName: string;

  constructor(public router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.a) {
        this.appName = params.a.toLowerCase();
      }
    });
  }

  ngOnInit() {
  }

  // Adds sticky header on scroll
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    const headerElement = document.getElementById('header-main');
    this.isVisible = window.pageYOffset >= 75;
  }
}
