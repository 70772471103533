import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  @Input() appName: string;
  appStoreLink;
  playStoreLink;
  appStoreLinks = {
    gocery: 'https://apps.apple.com/us/app/gocery/id1358522213?ls=1',
    pacific: 'https://apps.apple.com/us/app/pacific-fresh-food-market/id1454126755?ls=1',
    luckymoose: 'https://apps.apple.com/us/app/lucky-moose-food-mart/id1476952111?ls=1'
  };

  playStoreLinks = {
    gocery: 'https://play.google.com/store/apps/details?id=com.gocery.app',
    pacific: 'https://play.google.com/store/apps/details?id=com.gocery.app.pacific',
    luckymoose: 'https://play.google.com/store/apps/details?id=com.gocery.app.luckymoose'
  };

  constructor() {
  }

  ngOnInit() {
    this.appStoreLink = this.appStoreLinks[this.appName.toLowerCase()];
    this.playStoreLink = this.playStoreLinks[this.appName.toLowerCase()];
  }
}
