import { Injectable } from '@angular/core';
import { REST_ENDPOINT } from '../utils/constants';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }

  invite(request) {
    return this.http.post(`${REST_ENDPOINT}/invite`, request).toPromise()
  }

  createVerificationCode(request) {
    return this.http.post(`${REST_ENDPOINT}/login/identify`, request).toPromise()
  }

  validateVerificationCode(request) {
    return this.http.post(`${REST_ENDPOINT}/login/verify`, request).toPromise()
  }
}
