import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SubscribeModalComponent } from '../subscribe-modal/subscribe-modal.component';
import { SubscribeModalSuccessComponent } from '../subscribe-modal-success/subscribe-modal-success.component';


@Component({
  selector: 'app-subscribe-link',
  templateUrl: './subscribe-link.component.html',
  styleUrls: ['./subscribe-link.component.scss']
})
export class SubscribeLinkComponent implements OnInit {

  success = false;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  openDialog(): void {
    this.dialog.open(SubscribeModalComponent, {
      panelClass: 'custom-dialog-container',
      data: { success: this.success }
    });
  }

}
