import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InviteResolver } from './resolvers/invite.resolver';
import { HomeComponent } from './components/pages/home/home.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { SubscribeComponent } from './components/pages/subscribe/subscribe.component';
import { InviteComponent } from './components/pages/invite/invite.component';
import { DownloadsComponent } from './components/pages/downloads/downloads.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'terms', component: TermsComponent, resolve: { data: InviteResolver } },
  { path: 'privacy', component: PrivacyComponent, resolve: { data: InviteResolver } },
  { path: 'invite', component: InviteComponent, resolve: { data: InviteResolver } },
  { path: 'subscribe/:organizationKey', component: SubscribeComponent },
  { path: 'downloads', component: DownloadsComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [InviteResolver]
})
export class AppRoutingModule { }
