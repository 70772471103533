import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  appName: string;
  email: string;
  website: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data
      .subscribe((data) => {
        switch (data.data.a) {
          case 'pacific':
          case 'Pacific':
            this.appName = 'Pacific/Food Depot';
            this.email = 'pacificmarketca@gmail.com';
            this.website = 'pacificfreshfoodmarket.com';
            break;
          case 'luckymoose':
          case 'LuckyMoose':
            this.appName = 'Lucky Moose';
            this.email = 'luckymooseapp@gmail.com';
            this.website = 'lmfoodmart.com';
            break;
          default:
            this.appName = 'GOcery';
            this.email = 'support@gocery.ca';
            this.website = 'gocery.ca';
            break;
        }
      });
  }

  openWebsite() {
    window.open(`http://${this.website}`);
  }

}
