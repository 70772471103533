import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public appName;
  public phoneNumber: string;
  public email: string;
  public address1: string;
  public address2: string;

  public contactInfo = {
    gocery: {
      phoneNumber: '+1(800) 662-1968',
      email: 'info@gocery.ca',
      address1: '675 Cochrane Drive, East Tower, 6th Floor',
      address2: 'Markham, ON, L3R 0B8'
    },
    pacific: {
      phoneNumber: '(416) 661-8868',
      email: 'pacificmarketca@gmail.com',
      address1: '4734 Jane St',
      address2: 'North York, ON M3N 2L2'
    },
    luckymoose: {
      phoneNumber: '(416) 351-8688',
      email: 'luckymooseapp@gmail.com',
      address1: '393 Dundas St W',
      address2: 'Toronto, ON, M5T 1G6'
    }
  };

  constructor(public router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.a) {
        this.appName = params.a.toLowerCase();
        this.initialize(this.appName);
      }
    });

    this.initialize();
  }

  initialize(appName?: string) {
    let contactInfo;
    switch (appName) {
      case 'pacific':
        contactInfo = this.contactInfo[appName];
        break;
      case 'luckymoose':
        contactInfo = this.contactInfo[appName];
        break;
      default:
        contactInfo = this.contactInfo.gocery;
        break;
    }

    const { phoneNumber, email, address1, address2 } = contactInfo;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.address1 = address1;
    this.address2 = address2;
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

}
